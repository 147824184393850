/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { customGet, customPost } from "../../../../utils/services";
import NumberFormat from "react-number-format";
import Table from "../../../../components/common/Table";
import Select from "../../../../components/common/Select";
import toast from "react-hot-toast";
import Label from "../../../../components/common/Label";
import moment from "moment";
import Button from "../../../../components/common/Button";
import Spinner from "../../../../components/common/Spinner";
import Input from "../../../../components/common/Input";
import { TrashIcon } from "@heroicons/react/solid";

export const ReservasPendientes = (props) => {
  const { REACT_APP_API_URL } = process.env;
  const { token, registro, tipoPagos } = props;
  const [cargando, setCargando] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reservas, setReservas] = useState([]);
  const [reservasSeleccionadas, setReservasSeleccionadas] = useState([]);
  const [refetch, setRefetch] = useState(Date.now());
  const [tipoPago, setTipoPago] = useState(1);
  const [pagos, setPagos] = useState([]);
  const [cantidad, setCantidad] = useState("");
  const [noDocumento, setNoDocumento] = useState("");
  //const [totalReservaciones, setTotalReservaciones] = useState(0);

  useEffect(() => {
    setCargando(true);
    customGet(
      undefined,
      `reservacion/cliente/pendientes/${registro.id}`,
      token
    ).then((data) => {
      setReservas(data.reservas);
      setCargando(false);
    });
  }, [refetch]);

  const seleccionarReservas = (checked, item) => {
    if (checked) {
      setReservasSeleccionadas([...reservasSeleccionadas, item]);
    } else {
      let filtrar = reservasSeleccionadas.filter((x) => x.id !== item.id);
      setReservasSeleccionadas(filtrar);
    }
  };

  const pagarReservas = () => {
    const habitacionesSeleccionadas = [];

    for (const row of reservasSeleccionadas) {
      habitacionesSeleccionadas.push(row.id);
    }

    if (reservasSeleccionadas.length === 0) {
      toast.error("debe seleccionar al menos una reserva");
    } else {
      setLoading(true);
      let body = {
        ids_reservacion: habitacionesSeleccionadas,
        pagos: pagos,
        cliente_id: registro.id,
      };

      customPost(body, "reservacion/cliente/pagos/store/ids", token)
        .then((data) => {
          toast.success(data.data.msg);
          window.open(
            `${REACT_APP_API_URL}/imprimir-pago-reserva/${data.data.data.id}`
          );
          setLoading(false);
          setReservasSeleccionadas([]);
          setRefetch(Date.now());
          setPagos([])
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Ocurrio un error comuniquese con el administrador");
        });
    }
  };

  const addPago = (idPago) => {
    if (tipoPago != 1 && tipoPago != 5 && noDocumento === "") {
      return toast.error("el numero de documento no puede estar vacio");
    } else if (cantidad === "0" || cantidad === "") {
      return toast.error("la cantidad no puede estar vacia");
    } else if ((totalPagado(pagos) + Number(cantidad)) > totalReservaciones(reservasSeleccionadas)) {
      return toast.error('total de pagos no puede superar el total de las reservaciones seleccionadas')
    }

    else if (pagos.length === 0) {
      const item = getTipoPago(idPago);
      item.cantidad = Number(cantidad);
      item.noDocumento = noDocumento;
      setPagos([item]);
    }

    else {
      let existe = false;
      for (const row of pagos) {
        if (row.id === Number(idPago)) {
          row.cantidad += Number(cantidad);
          row.noDocumento += `,${noDocumento}`;
          setPagos(pagos);
          existe = true;
        }
      }

      if (existe === false) {
        const newPago = getTipoPago(idPago);
        newPago.cantidad = Number(cantidad);
        newPago.noDocumento = noDocumento;
        setPagos([...pagos, newPago]);
      }
    }

    setCantidad("");
    setNoDocumento("");
    setTipoPago(1);
  };

  function getTipoPago(id) {
    for (const item of tipoPagos) {
      if (item.id === Number(id)) {
        return item;
      }
    }
  }

  function handleChangeCantidad(e) {
    setCantidad(e.target.value);
  }

  function handleChangeNoDocumento(e) {
    setNoDocumento(e.target.value);
  }

  const Badget = (text, colorText, backgroud) => {
    return <span className={`inline-flex ml-2 items-center rounded-md ${colorText} px-2 py-1 text-lg font-medium ${backgroud}`}>
      {text}
    </span>
  }

  function totalReservaciones(reservasSeleccionadas) {
    const totalSaldo = reservasSeleccionadas.reduce((sum, record) => {
      return sum + Number(record.saldo);
    }, 0);

    return totalSaldo
  }

  function totalPagado(pagos) {
    const totalPagos = pagos.reduce((sum, record) => {
      return sum + Number(record.cantidad);
    }, 0);

    return totalPagos;
  }

  const imprimirReservas = () => {
    const ids = [];
    for (const item of reservasSeleccionadas) {
      ids.push(item.id);
    }
    if (reservasSeleccionadas.length === 0) {
      toast.error("debe seleccionar al menos una reserva");
    } else {
      window.open(
        `${REACT_APP_API_URL}/imprimir-reserva/${registro.id
        }/${ids.toString()}`
      );
    }
  };

  return (
    <div className="grid grid-cols-12 gap-2 mb-3">
      <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-4 border p-3 rounded-lg hover:shadow-md">
        <Label text="Datos cliente" />
        <hr />
        <ul>
          <li>
            <span className="text-xs text-gray-800">
              Nombre: {`${registro.nombre} ${registro.apellido}`}{" "}
            </span>
          </li>
          <li>
            <span className="text-xs text-gray-800">DPI: {registro.dpi} </span>
          </li>
          <li>
            <span className="text-xs text-gray-800">NIT: {registro.nit} </span>
          </li>
          <li>
            <span className="text-xs text-gray-800">
              Celular: {registro?.celular}
            </span>
          </li>
          <li>
            <span className="text-xs text-gray-800">
              Correo: {registro?.correo}
            </span>
          </li>
          <li>
            <span className="text-xs text-gray-800">
              Fecha registro:{" "}
              {moment(registro?.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </span>
          </li>
        </ul>
      </div>

      <div className="col-span-12 xl:col-span-9 lg:col-span-9 md:col-span-9 border p-3 rounded-lg hover:shadow-md">
        <Label text="Listado de reservaciones" />
        <Table
          cargando={cargando}
          headers={[
            "Select",
            "Habitacion",
            "Estado",
            "Personas",
            "Entrada",
            "Salida",
            "Costo",
            "Saldo",
            "",
          ]}
          body={reservas?.map((item, index) => (
            <tr
              key={`res-${item.id}-${index}`}
              className="hover:bg-gray-100 cursor-pointer"
            >
              <td className="px-1 text-xs text-primaryFont">
                <input
                  className=""
                  type="checkbox"
                  onChange={(e) => {
                    seleccionarReservas(e.target.checked, item);
                  }}
                />
              </td>
              <td className="px-1 text-xs text-primaryFont">
                {item?.habitacion?.nombre}
              </td>
              <td className="px-1 text-xs text-primaryFont">
                {item?.estado?.nombre}
              </td>
              <td className="px-1 text-xs text-primaryFont">
                {item?.personas}
              </td>
              <td className="px-1 text-xs text-primaryFont">
                {moment(item?.entrada).format("DD-MM-YYYY")}
              </td>
              <td className="px-1 text-xs text-primaryFont">
                {moment(item?.salida).format("DD-MM-YYYY")}
              </td>
              <td className="px-1 text-xs text-primaryFont">
                <NumberFormat
                  value={item?.costo}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Q"}
                />
              </td>
              <td className="px-1 text-xs text-primaryFont">
                <NumberFormat
                  value={item?.saldo}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Q"}
                />
              </td>
              <td className="px-1 text-xs text-primaryFont"></td>
            </tr>
          ))}
        />
      </div>
      <div className="col-span-12 border rounded-lg p-3">
        <div className="flex flex-row items-end mb-2">
          <Select
            name="tipo_pago"
            value={tipoPago}
            onChange={(e) => {
              setTipoPago(e.target.value);
            }}
            label="Tipo de pago"
            valueInit=""
            selectInit="seleccionar tipo de pago"
            list={tipoPagos?.map((item, index) => {
              return (
                <option value={item?.id} key={item?.nombre + index}>
                  {item?.nombre}
                </option>
              );
            })}
          />
          <Input
            className="ml-2"
            type="number"
            name="cantidad"
            value={cantidad}
            onChange={handleChangeCantidad}
            validacion={false}
            minimo={0}
            label="Cantidad"
          />
          <div className="w-24 ml-2 mr-2">
            {loading ? (
              <Spinner />
            ) : (
              <Button
                className="ml-2"
                type="submit"
                onClick={() => {
                  addPago(tipoPago);
                }}
                text="Agregar"
              />
            )}
          </div>
          {
            Badget(`Total reservaciones: ${totalReservaciones(reservasSeleccionadas)}`, 'bg-blue-100', 'text-blue-600')
          }
          {
            Badget(`Pendiente: ${totalReservaciones(reservasSeleccionadas) - totalPagado(pagos)}`, 'bg-red-100', 'text-red-600')
          }
          {
            Badget(`Pagado: ${totalPagado(pagos)}`, 'bg-green-100', 'text-green-600')
          }
        </div>
        <div className="flex flex-row items-end mb-2">
          {(tipoPago != 1 && tipoPago != 5) &&
            <Input
              className="ml-2"
              type="text"
              name="noDocumento"
              value={noDocumento}
              onChange={handleChangeNoDocumento}
              validacion={false}
              label="No Documento"
            />
          }
        </div>
        <Table
          cargando={cargando}
          headers={["Tipo pago", "Cantidad", "Eliminar"]}
          body={pagos?.map((item, index) => (
            <tr
              key={`res-${item.id}-${index}`}
              className="hover:bg-gray-100 cursor-pointer"
            >
              <td className="px-1 text-xs text-primaryFont">{item?.nombre}</td>
              <td className="px-1 text-xs text-primaryFont">
                <NumberFormat
                  value={item?.cantidad}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"Q"}
                />
              </td>
              <td className="px-1 text-xs text-primaryFont">
                <button
                  type="button"
                  onClick={() => {
                    let listPagos = pagos.filter(x => x.id !== item.id);
                    setPagos(listPagos);
                  }}
                  className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                >
                  <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                  <span className="tooltiptext">Eliminar</span>
                </button>
              </td>
            </tr>
          ))}
        />
      </div>
      <div className="col-span-12">
        <div className="flex flex-row items-end">
          <div className="w-24 ml-2">
            {loading ? (
              <Spinner />
            ) : (
              <Button type="submit" onClick={() => {
                if (totalPagado(pagos) < totalReservaciones(reservasSeleccionadas)) {
                  toast.error('debe agregar los pagos correspondientes a las reservaciones seleccionadas')
                } else {
                  pagarReservas()
                }
              }} text="Pagar" />
            )}
          </div>

          <div className="w-24 ml-2">
            <Button type="submit" onClick={imprimirReservas} text="Imprimir" />
          </div>
        </div>
      </div>
    </div>
  );
};
