/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from "react";
import { Formik } from "formik";
import { customDelete, customPost } from "../../../utils/services";
import { TrashIcon } from "@heroicons/react/solid";
import Spinner from "../../../components/common/Spinner";
import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import Table from "../../../components/common/Table";
import Button from "../../../components/common/Button";
import toast from "react-hot-toast";
import * as Yup from "yup";

const validaciones = Yup.object().shape({
  //tipo_pago_id: Yup.string().required("tipo pago es requerido"),
  //monto: Yup.string().required("monto es requerido"),
});

const {
  REACT_APP_API_URL
} = process.env;


export const PagosOrden = (props) => {
  const [total, setTotal] = useState(0);
  const [totalPagos, setTotalPagos] = useState(0);
  const [pagos, setPagos] = useState(props.registro.pagos);
  const [finalizarOrden, setFinalizarOrden] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);

  let isOk = "bg-green-100 text-green-600 border-4 border-green-500";
  let pending = "bg-red-100 text-red-600 border-4 border-red-500";

  useEffect(() => {
    let total = 0;
    props.registro.detalle.forEach((element) => {
      total += Number(element.precio) * element.cantidad;
    });

    setTotal(total);

    let pagoTemp = pagos;
    let sumaTotal = 0;
    pagoTemp.forEach(element => {
      sumaTotal += Number(element.monto);
    });

    if (sumaTotal >= total) {
      setFinalizarOrden(true);
    }

    setTotalPagos(sumaTotal)

  }, []);

  const aplicarPagos = async (pago) => {
    await customPost(pago, `ventas/pagos/${props.registro.id}`, props.token)
      .then(async (data) => {
        if (data?.status >= 200 && data?.status <= 210) {
          toast.success(data?.data?.msg);
          props.setRefetch(true);
          data.data.data.tipo_pago = pago.tipo_pago;

          setPagos([...pagos, data.data.data]);
          setTimeout(() => {
            props.setRefetch(false);
          }, 700);

          let pagoTemp = [...pagos, data.data.data]
          let sumaTotal = 0;
          pagoTemp.forEach(element => {
            sumaTotal += Number(element.monto);
          });

          if (sumaTotal >= total) {
            setFinalizarOrden(true);
          }

          setTotalPagos(sumaTotal);

        }
      })
      .catch(function (error) {
        if (error.response.status >= 400 && error.response.status <= 450) {
          toast.error(error.response.data.error);
        } else if (error.response.status >= 500) {
          toast.error("Error interno comuniquese con el administrador");
        }
      }).finally(() => setLoadingAdd(false));
  };

  const eliminarPago = (id) => {
    customDelete(id, `ventas/pagos`, props.token).then(() => {
      toast.success("pago eliminado con exito")
      let filtrar = pagos.filter(x => x.id !== id);
      setPagos(filtrar);
      props.setRefetch(true);

      setTimeout(() => {
        props.setRefetch(false);
      }, 1000);

      let pagoTemp = filtrar;
      let sumaTotal = 0;
      pagoTemp.forEach(element => {
        sumaTotal += Number(element.monto);
      });

      if (sumaTotal < total) {
        setFinalizarOrden(false);
      }

      setTotalPagos(sumaTotal);
    });
  }

  return (
    <Formik
      initialValues={{
        tipo_pago_id: "",
        monto: "",
        no_documento: ""
      }}
      validationSchema={validaciones}
      onSubmit={async () => {
        let ordenesPendientes = 0;

        for (const item of props?.registro?.detalle) {
          if (item.delivered_at === null) {
            ordenesPendientes += 1;
          }
        }

        if (ordenesPendientes === 0) {
          await customPost(
            undefined,
            `ventas/finalizar/${props.registro.id}`,
            props.token
          )
            .then(async (data) => {
              if (data?.status >= 200 && data?.status <= 210) {
                toast.success("venta finalizada con exito");
                props.setRefetch(true);
                props.setModalPagos(false);
                window.open(`${REACT_APP_API_URL}/imprimir-orden/${props.registro.id}`, '_blank');
                setTimeout(() => {
                  props.setRefetch(false);
                }, 1000);
              }
            })
            .catch(function (error) {
              if (error.response.status >= 400 && error.response.status <= 450) {
                toast.error(error.response.data.error);
              } else if (error.response.status >= 500) {
                toast.error("Error interno comuniquese con el administrador");
              }
            });
        } else {
          toast.error("Hay productos pendientes de entrega, debe entregar todos los productos para finalizar la orden.");
        }
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <div className="grid grid-cols-12 gap-2 p-3 mb-14 items-end">
          <div className="col-span-12">
            <div className="flex justify-center items-center">
              <div className="p-2 w-40 h-18 bg-primaryOpacity text-center rounded-lg text-2xl text-primary border-4 border-blue-500">
                <label className="text-sm xl:text-lg md:text-base">Total orden</label>
                <br />
                <span className="text-sm xl:text-lg md:text-base">Q{total}</span>
              </div>
              <div className={`p-2 mx-2 w-40 h-18 bg-primaryOpacity text-center rounded-lg text-2xl ${finalizarOrden ? isOk : pending}`}>
                <label className="text-sm xl:text-lg md:text-base">Pendiente</label>
                <br />
                <span className="text-sm xl:text-lg md:text-base">Q{total - totalPagos}</span>
              </div>
              <div className={`p-2 w-40 h-18 bg-primaryOpacity text-center rounded-lg text-2xl ${finalizarOrden ? isOk : pending}`}>
                <label className="text-sm xl:text-lg md:text-base">Total pagos</label>
                <br />
                <span className="text-sm xl:text-lg md:text-base">Q{totalPagos}</span>
              </div>
              {/* <div className={`p-2 w-40 h-18 bg-primaryOpacity text-center rounded-lg text-2xl ml-2 ${finalizarOrden ? isOk : pending }`}>
                <label className="text-sm xl:text-lg md:text-base">Vuelto</label>
                <br/>
                <span className="text-sm xl:text-lg md:text-base">Q{ Number(values.monto) > (total - totalPagos) ? Number(values.monto) - (total - totalPagos) : 0}</span>
              </div> */}
            </div>
          </div>
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6">
            <Select
              id="tipo_pago_id"
              name="tipo_pago_id"
              value={values.tipo_pago_id}
              onChange={handleChange}
              validacion={true}
              label="Tipo pago"
              valueInit=""
              selectInit="seleccionar tipo pago"
              list={props?.tipoPagos?.map((item) => {
                return (
                  <option value={item?.id} key={item?.nombre}>
                    {item?.nombre}
                  </option>
                );
              })}
            />
          </div>
          {(values.tipo_pago_id != 1 && values.tipo_pago_id != 5 && values.tipo_pago_id != '') &&
            <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6">
              <Input
                type="text"
                name="no_documento"
                value={values.no_documento}
                onChange={handleChange}
                validacion={false}
                label="No Documento"
              />
            </div>
          }
          <div className="col-span-12 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-6">
            <Input
              type="number"
              name="monto"
              value={values.monto}
              onChange={handleChange}
              validacion={true}
              label="Monto"
            />
          </div>
          <div className="col-span-12 xl:col-span-2 lg:col-span-2 md:col-span-2">
            {
              loadingAdd ? <Spinner /> :
                <Button
                  text="Agregar"
                  onClick={() => {
                    if (values.tipo_pago_id != 1 && values.tipo_pago_id != 5 && values.no_documento === "") {
                      return toast.error("el numero de documento no puede estar vacio");
                    } else if (values.tipo_pago_id === "" || values.monto === "") {
                      toast.error('tipo de pago y monto son requeridos')
                    } else {
                      setLoadingAdd(true);
                      let pago = {};
                      let sel = document.getElementById("tipo_pago_id");
                      let text = sel.options[sel.selectedIndex].text;
                      pago = {
                        tipo_pago_id: Number(values.tipo_pago_id),
                        monto: values.monto > (total - totalPagos) ? (total - totalPagos) : values.monto,
                        no_documento: values.no_documento,
                        tipo_pago: {
                          id: 0,
                          nombre: text
                        }
                      };

                      aplicarPagos(pago);
                      values.monto = "";
                      values.tipo_pago_id = "";
                      values.no_documento = "";
                    }
                  }}
                />
            }
          </div>
          <div className="col-span-12">
            <Table
              headers={["Tipo pago", "Monto", "Eliminar"]}
              body={pagos.map((item, index) => (
                <tr
                  className="hover:bg-slate-100"
                  key={item?.tipo_pago?.nombre + index}
                >
                  <td className="px-2 py-1.5 text-xs text-primaryFont">
                    {item?.tipo_pago?.nombre}
                  </td>
                  <td className="px-2 text-xs text-primaryFont">
                    {item?.monto}
                  </td>
                  <td>
                    {" "}
                    <button
                      type="button"
                      onClick={() => {
                        eliminarPago(item.id);
                      }}
                      className={`hover:bg-red-200 hover:rounded-lg p-1 tooltip`}
                    >
                      <TrashIcon className="h-6 w-6  text-red-600 hover:text-red-600" />
                      <span className="tooltiptext">Eliminar</span>
                    </button>
                  </td>
                </tr>
              ))}
            />
          </div>
          {
            finalizarOrden &&
            <div className="col-span-3">
              {
                isSubmitting ? <Spinner /> :
                  <Button
                    text="Finalizar orden"
                    onClick={handleSubmit}
                  />
              }

            </div>
          }
        </div>
      )}
    </Formik>
  );
};
